import React, { useState } from 'react'
import { Formik } from 'formik';
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'
import axios from 'axios';
import { toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';


function ContactPage() {

    const [loading, setLoading] = useState(false);

    return (
        <>

            <HeaderComponent />

            <div>
                {/*  ************************* Page Title Starts Here ************************** */}
                <div className="page-nav no-margin row">
                    <div className="container">
                        <div className="row">
                            <h2>Contact Us</h2>
                            <ul>
                                <li> <a href="#"><i className="fas fa-home" /> Home</a></li>
                                <li><i className="fas fa-angle-double-right" /> Contact US</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*  ************************* Contact Us Starts Here ************************** */}

                <div className="row contact-rooo no-margin">
                    <div className="container">
                        <div className="row">
                            <div style={{ padding: 20 }} className="col-sm-7">
                                <h2>Contact Form</h2> <br />

                                <Formik
                                    initialValues={{
                                        name: "",
                                        email: "",
                                        mobile: "",
                                        designation: "",
                                        photo: null,
                                        id_proof_front: null,
                                        id_proof_back: null
                                    }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.name) {
                                            errors.name = "Name is required.";
                                        }

                                        if (!values.mobile) {
                                            errors.mobile = "Mobile is required.";
                                        }

                                        if (!values.photo) {
                                            errors.photo = "Photo is required.";
                                        }

                                        if (!values.id_proof_back) {
                                            errors.id_proof_back = "ID Proof Back is required.";
                                        }

                                        if (!values.id_proof_front) {
                                            errors.id_proof_front = "ID Proof Front is required.";
                                        }

                                        return errors;
                                    }}

                                    onSubmit={(values, { resetForm }) => {

                                        setLoading(true);

                                        let formData = new FormData();
                                        formData.append("name", values.name);
                                        formData.append("email", values.email);
                                        formData.append("mobile", values.mobile);
                                        formData.append("designation", values.designation);
                                        formData.append("photo", values.photo);
                                        formData.append("id_proof_front", values.id_proof_front);
                                        formData.append("id_proof_back", values.id_proof_back);

                                        axios.post(`https://api.kusum.org.in/user`, formData).then(response => {

                                            // resetForm();

                                            toast("Data Submitted Successfully!", {
                                                type: "success"
                                            });

                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 2000);


                                        }).catch(error => {
                                            toast(error.message, {
                                                type: "error"
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        })

                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        setFieldValue,
                                        isSubmitting
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>Enter Name </label><span>:</span></div>
                                                <div className="col-sm-8">
                                                    <input type="text" placeholder="Enter Name" name="name" value={values.name} onChange={handleChange} onBlur={handleBlur} className="form-control input-sm" />
                                                    {
                                                        errors.name && touched.name && <span className='text-danger'>{errors.name}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>Email Address </label><span>:</span></div>
                                                <div className="col-sm-8"><input type="text" name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Email Address" className="form-control input-sm" />
                                                    {
                                                        errors.email && touched.email && <span className='text-danger'>{errors.email}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>Mobile Number</label><span>:</span></div>
                                                <div className="col-sm-8"><input type="text" name="mobile" value={values.mobile} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Mobile Number" className="form-control input-sm" />
                                                    {
                                                        errors.mobile && touched.mobile && <span className='text-danger'>{errors.mobile}</span>
                                                    }
                                                </div>
                                            </div>


                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>Designation</label><span>:</span></div>
                                                <div className="col-sm-8">
                                                    {/* <input type="text" name="mobile" value={values.mobile} onChange={handleChange} onBlur={handleBlur} placeholder="Enter Mobile Number" className="form-control input-sm" /> */}
                                                    <select className="form-control input-sm" name="designation" value={values.designation} onChange={handleChange} onBlur={handleBlur}>
                                                        <option value="">Choose Designation</option>
                                                        <option value="Trust Member">Trust Member</option>
                                                    </select>
                                                    {
                                                        errors.designation && touched.designation && <span className='text-danger'>{errors.designation}</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>Your Photo</label><span>:</span></div>
                                                <div className="col-sm-8">
                                                    <input type="file" name="photo" onChange={(e) => {
                                                        setFieldValue("photo", e.target.files[0]);
                                                    }} onBlur={handleBlur} className="form-control input-sm" />
                                                    {
                                                        errors.photo && touched.photo && <span className='text-danger'>{errors.photo}</span>
                                                    }
                                                </div>
                                            </div>


                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>ID Proof (Front)</label><span>:</span></div>
                                                <div className="col-sm-8">
                                                    <input type="file" name="id_proof_front" onChange={(e) => {
                                                        setFieldValue("id_proof_front", e.target.files[0]);
                                                    }} onBlur={handleBlur} className="form-control input-sm" />
                                                    {
                                                        errors.id_proof_front && touched.id_proof_front && <span className='text-danger'>{errors.id_proof_front}</span>
                                                    }
                                                </div>
                                            </div>


                                            <div className="row cont-row">
                                                <div className="col-sm-3"><label>ID Proof (Back)</label><span>:</span></div>
                                                <div className="col-sm-8">
                                                    <input type="file" name="id_proof_back" onChange={(e) => {
                                                        setFieldValue("id_proof_back", e.target.files[0]);
                                                    }} onBlur={handleBlur} className="form-control input-sm" />
                                                    {
                                                        errors.id_proof_back && touched.id_proof_back && <span className='text-danger'>{errors.id_proof_back}</span>
                                                    }
                                                </div>
                                            </div>


                                            <div style={{ marginTop: 10 }} className="row">
                                                <div style={{ paddingTop: 10 }} className="col-sm-3"><label /></div>
                                                <div className="col-sm-8">
                                                    <button type='submit' disabled={loading} className="btn btn-primary btn-sm">Send Message</button>
                                                    <TailSpin
                                                        height="50"
                                                        width="50"
                                                        color="#4fa94d"
                                                        ariaLabel="tail-spin-loading"
                                                        radius="1"
                                                        wrapperClass=""
                                                        visible={loading}
                                                        wrapperStyle={{
                                                            marginTop: "20px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>


                            </div>
                            <div className="col-sm-5">
                                <div style={{ margin: 50 }} className="serv">
                                    <h2 style={{ marginTop: 10 }}>Address</h2>
                                    Kusum Educational Trust (Regd.)<br />
                                    265 GALI NO.7/3/5, Shakti Vihar <br />
                                    Meetapur, New Delhi-110044 <br />
                                    Phone: +91 8285223535 / 9716989808 <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <FooterComponent />

        </>
    )
}

export default ContactPage