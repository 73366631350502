import React, { useEffect } from 'react'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'

function GalleryPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <HeaderComponent />

            <div>
                {/*  ************************* Page Title Starts Here ************************** */}
                <div className="page-nav no-margin row">
                    <div className="container">
                        <div className="row">
                            <h2>Our Gallery</h2>
                            <ul>
                                <li> <a href="#"><i className="fas fa-home" /> Home</a></li>
                                <li><i className="fas fa-angle-double-right" /> Gallery</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*  ************************* Gallery Starts Here ************************** */}
                <div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/1.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/1.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/2.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/2.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/3.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/3.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/4.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/4.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/5.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/5.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/6.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/6.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/7.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/7.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/8.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/8.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/9.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/9.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/10.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/10.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/11.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/11.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/12.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/12.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/13.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/13.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/14.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/14.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/15.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/15.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/16.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/16.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/17.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/17.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/18.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/18.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/19.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/19.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/20.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/20.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/21.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/21.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/22.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/22.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/23.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/23.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/24.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/24.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/25.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/25.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/26.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/26.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/27.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/27.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/28.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/28.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/29.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/29.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/30.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/30.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/31.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/31.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/32.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/32.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/33.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/33.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/34.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/34.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/35.jpg" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/35.jpg" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/36.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/36.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/37.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/37.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/38.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/38.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/39.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/39.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/40.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/40.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/41.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/41.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/42.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/42.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/43.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/43.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/44.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/44.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/45.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/45.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/46.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/46.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/47.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/47.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/48.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/48.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/49.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/49.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/50.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/50.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/51.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/51.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/52.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/52.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/53.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/53.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/54.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/54.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/55.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/55.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/56.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/56.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/57.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/57.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/58.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/58.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/59.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/59.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/60.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/60.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/61.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/61.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/62.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/62.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/63.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/63.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/64.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/64.JPG" alt /></a>
                            </div>
                            <div className="col-md-3">
                                <a className="example-image-link" href="assets/images/gallery/65.JPG" data-lightbox="example-set" data-title="Kusum Educational Trust"><img className="img-thumbnail" src="assets/images/gallery/65.JPG" alt /></a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ######## Gallery End ####### */}
            </div>



            <FooterComponent />
        </>
    )
}

export default GalleryPage