import React, { useEffect } from 'react'
import FooterComponent from '../components/FooterComponent';
import HeaderComponent from '../components/HeaderComponent';

function DonatePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>

            <HeaderComponent />


            <div>
                <div className="page-nav no-margin row">
                    <div className="container">
                        <div className="row">
                            <h2>UPI PAYMENT</h2>
                        </div>
                        <a href="assets/images/barcode.jpg" download>
                            <img src="assets/images/barcode.jpg" alt="barcode" width={200} height={200} />
                            <h5>Download and Scan the UPI Code to Donate through</h5>
                            <h5>UPI ID/QR Code (UPI, PAYTM, PHONEPE, GPAY)</h5>
                            <h6>A/c No. 38232954587 |  IFSC Code: SBIN0003219 | SBI, New Delhi</h6> </a><p><a href="assets/images/barcode.jpg" download>
                            </a><button className="btn btn-sm btn-warning" style={{ height: 50, width: 150 }}><a href="assets/images/barcode.jpg" download /><a href="assets/images/barcode.jpg" download> CONTRIBUTE NOW </a></button></p><a href="assets/images/barcode.jpg" download>
                            <img src="assets/images/pancard.jpg" alt="pancard" width={300} height={200} />
                        </a></div><a href="assets/images/barcode.jpg" download>
                    </a></div><a href="assets/images/barcode.jpg" download>
                </a></div>



            <FooterComponent />

        </>
    )
}

export default DonatePage