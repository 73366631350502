import React from 'react'
import { Link } from 'react-router-dom'

function FooterComponent() {
    return <>
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <h2>About Us</h2>
                            <p>
                                An educational institution is a place where people of different ages gain an education, including preschools, childcare, primary-elementary schools, Technical-high schools.
                                They provide a large variety of learning environments and learning spaces.
                            </p>
                            <p>We focus on technologies that promise to reduce costs, streamline processes and speed time-to-market, Backed by our strong quality processes and rich educational experience.
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <h2>Useful Links</h2>
                            <ul className="list-unstyled link-list">
                                <li><a ui-sref="about" href="#/about">About us</a><i className="fa fa-angle-right" /></li>
                                <li><a ui-sref="portfolio" href="#/portfolio">Portfolio</a><i className="fa fa-angle-right" />
                                </li>
                                <li><a ui-sref="products" href="#/products">Latest jobs</a><i className="fa fa-angle-right" />
                                </li>
                                <li><a ui-sref="gallery" href="#/gallery">Gallery</a><i className="fa fa-angle-right" /></li>
                                <li><a ui-sref="contact" href="#/contact">Contact us</a><i className="fa fa-angle-right" /></li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-sm-12 map-img">
                            <h2>Contact Us</h2>
                            <address className="md-margin-bottom-40">
                                Kusum Educational Trust (Regd.) <br />
                                265 GALI NO.7/3/5, Shakti Vihar <br />
                                Meetapur, New Delhi-110044 <br />
                                Phone: +91 8285223535 / 9716989808 <br />
                                Mobile: +91 9716838484 / 9716993166 <br />
                                Email: <a href="trustkusumeducational@gmail.com" className>trustkusumeducational@gmail.com</a><br />
                                Web: <Link to={"/"} className>https://kusum.org.in</Link>
                            </address>
                        </div>
                    </div>
                    <div className="nav-box row clearfix">
                        <div className="inner col-md-9 clearfix">
                            <ul className="footer-nav clearfix">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About</Link></li>
                                <li><Link to="/gallery">Gallery</Link></li>
                                <li><Link to="/service">Servies</Link></li>
                                <li><Link to="/donate">Donation</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="donate-link col-md-3"><Link to="/donate" className="btn btn-primary "><span className="btn-title">Donate Now</span></Link></div>
                    </div>
                </div>
            </footer>
            <div className="copy">
                <div className="container">
                    <a href="https://webox.in" target="_blank">2020 © Developed by Webox</a>
                    <span>
                        <a><i className="fab fa-github" /></a>
                        <a><i className="fab fa-google-plus-g" /></a>
                        <a><i className="fab fa-pinterest-p" /></a>
                        <a><i className="fab fa-twitter" /></a>
                        <a><i className="fab fa-facebook-f" /></a>
                    </span>
                </div>
            </div>
        </div>

    </>
}

export default FooterComponent