import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

function SliderComponent({
    images
}) {
    return (
        <Carousel>
            {
                images.map((image, index) => (
                    <Carousel.Item key={`slider-${index}`}>
                        <img
                            className="d-block w-100"
                            src={image.url}
                            style={{
                                height: "80vh"
                            }}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <h3 style={{ color: "#ffffff" }}>{image.heading}</h3>
                            <p>{image.description}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            }

        </Carousel>
    )
}

export default SliderComponent