import React, { useEffect } from 'react'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'

function ServicePage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <HeaderComponent />

            <div>
                {/*  ************************* Page Title Starts Here ************************** */}
                <div className="page-nav no-margin row">
                    <div className="container">
                        <div className="row">
                            <h2>Popular Causes</h2>
                            <ul>
                                <li> <a href="#"><i className="fas fa-home" /> Home</a></li>
                                <li><i className="fas fa-angle-double-right" /> About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ################# Events Start Here#######################-*/}
                <section className="events">
                    <div className="container">
                        <div className="event-ro row">
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_08.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000 </p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_06.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000</p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_04.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000</p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_05.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000</p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_06.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000</p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <div className="event-box">
                                    <img src="assets/images/events/image_07.jpg" alt />
                                    <h4>Child Education in India</h4>
                                    <p className="raises"><span>Raised : Rs.1001</span> / Rs.100,000</p>
                                    <p className="desic">one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.</p>
                                    <button className="btn btn-success btn-sm">Donate Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />

        </>
    )
}

export default ServicePage