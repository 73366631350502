import React, { useEffect } from 'react'
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'

function AboutPage() {

    useEffect   (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>

            <HeaderComponent />

            <div>
                {/*  ************************* Page Title Starts Here ************************** */}
                <div className="page-nav no-margin row">
                    <div className="container">
                        <div className="row">
                            <h2>About Our Charity</h2>
                            <ul>
                                <li> <a href="#"><i className="fas fa-home" /> Home</a></li>
                                <li><i className="fas fa-angle-double-right" /> About Us</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/*  ************************* About Us Starts Here ************************** */}
                <div className="about-us container-fluid">
                    <div className="container">
                        <div className="row natur-row no-margin w-100">
                            <div className="text-part col-md-6">
                                <h2>About Our Charity</h2>
                                <p>Kusum Educational Trust (Registered NCT Delhi, Govt. of India) The Education Trust is a nonprofit organization
                                    that promotes high academic achievement for all students. With a goal to close opportunity and achievement.</p>
                                <p> An educational institution is a place where people of different ages gain an education, including preschools, childcare, primary-elementary
                                    schools, secondary-high schools, and universities. They provide a large variety of learning environments and learning spaces..</p>
                                <p>To preserve and provide a sustainable environment for healthy mothers, children and families by providing Nutrition, Education and Counseling. To prevent
                                    child abandonment. To preserve family by meeting their needs through community programs, To prevent child labour.</p>
                            </div>
                            <div className="image-part col-md-6">
                                <div className="about-quick-box row">
                                    <div className="col-md-6">
                                        <div className="about-qcard">
                                            <i className="fas fa-user" />
                                            <p>Become a Volunteer</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-qcard ">
                                            <i className="fas fa-search-dollar red" />
                                            <p>Quick Fundraiser</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-qcard ">
                                            <i className="fas fa-donate yell" />
                                            <p>Give Donation</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about-qcard ">
                                            <i className="fas fa-hands-helping blu" />
                                            <p>Help Someone</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ################# Mission Vision Start Here #######################-*/}
                <section className="container-fluid mission-vision">
                    <div className="container">
                        <div className="row mission">
                            <div className="col-md-6 mv-det">
                                <h1>Our Mission</h1>
                                <p>To preserve and provide a sustainable environment for healthy mothers, children and families by providing Nutrition, Education and Counseling. To prevent child abandonment. To preserve family by meeting their needs through community programs, To prevent child labour.</p>
                            </div>
                            <div className="col-md-6 mv-img">
                                <img src="assets/images/misin.jpg" alt />
                            </div>
                        </div>
                        <div className="row vision">
                            <div className="col-md-6 mv-img">
                                <img src="assets/images/vision.jpg" alt />
                            </div>
                            <div className="col-md-6 mv-det">
                                <h1>Our Vision</h1>
                                <p>Trust Vision Give every child an opportunity to succeed Cultivate a personalised learning pathway that challenges, engages and development and Support for students.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <FooterComponent />
        </>
    )
}

export default AboutPage