import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import DonatePage from './pages/DonatePage';
import GalleryPage from './pages/GalleryPage';
import HomePage from "./pages/HomePage";
import ServicePage from './pages/ServicePage';


function App() {
  return <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/service' element={<ServicePage />} />
        <Route path='/gallery' element={<GalleryPage />} />
        <Route path='/donate' element={<DonatePage />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
    <ToastContainer />
  </>
}

export default App;
