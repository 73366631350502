import React from 'react'
import { Link } from 'react-router-dom'

function HeaderComponent() {
    return (
        <header className="continer-fluid ">
            <div className="header-top" style={{ display: 'none' }}>
                <div className="container">
                    <div className="row col-det">
                        <div className="col-lg-6 d-none d-lg-block">
                            <ul className="ulleft">
                                <li>
                                    <i className="far fa-envelope" />
                                    trustkusumeducational@gmail.com
                                    <span>|</span>
                                </li>
                                <li>
                                    <i className="fas fa-phone-volume" />
                                    +91 8285223535
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6 folouws">
                            <ul className="ulright">
                                <li>
                                    <i className="fab fa-facebook-square" />
                                </li>
                                <li>
                                    <i className="fab fa-instagram" />
                                </li>
                                <li>
                                    <a href="https://kusumeducationaltrust.com/contact" className="btn btn-warning btn-sm "><span className="btn-title">Join
                                        Us</span></a>
                                </li>
                                <li>
                                    <Link to="/donate" className="btn btn-primary btn-sm "><span className="btn-title">Donate
                                        Now</span></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="menu-jk" className="header-bottom">
                <div className="container">
                    <div className="row nav-row">
                        <div className="col-lg-3 col-md-12 logo">
                            <Link to="/">
                                <img src="assets/images/logo.jpg" alt />
                            </Link><a data-toggle="collapse" data-target="#menu" href="#menu"><i className="fas d-block d-lg-none  small-menu fa-bars" /></a>
                        </div>
                        <div id="menu" className="col-lg-9 col-md-12 d-none d-lg-block nav-col">
                            <ul className="navbad">
                                <li className="nav-item active">
                                    <Link className="nav-link" to={"/"}>Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/about"}>About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/service"}>Services</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/gallery"}>Gallery</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/donate"}>Donate</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to={"/contact"}>Contact US</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}

export default HeaderComponent