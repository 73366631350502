import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import FooterComponent from '../components/FooterComponent'
import HeaderComponent from '../components/HeaderComponent'
import SliderComponent from '../components/SliderComponent'


function HomePage() {

    const images = [

        {
            heading: "",
            description: "",
            url: "assets/images/slider/slider-5.jpeg"
        },


        {
            heading: "",
            description: "",
            url: "assets/images/slider/event.jpeg"
        },

        {
            heading: "Preschools, Primary-elementary Education",
            description: "Large variety of learning environments and learning spaces",
            url: "assets/images/slider/slider-1.jpg"
        },
        {
            heading: "It's time for better help for education.",
            description: `An educational institution is a place where people of
            different ages gain an education, including Technical Education`,
            url: "assets/images/slider/slider-3.jpg"
        },
        {
            heading: "Most Attractive Educational Children Space",
            description: `The Education Trust is a nonprofit organization that promotes high academic achievement
            for all students. With a goal to close opportunity and achievement`,
            url: "assets/images/slider/slider-2.jpg"
        },
        {
            heading: "Most Attractive Technical Digital Education",
            description: `The Education Trust is a nonprofit organization that promotes high academic achievement
            for all students. With a goal to close opportunity and achievement`,
            url: "assets/images/slider/slider-4.jpg"
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return <>
        <HeaderComponent />
        <div>
            <SliderComponent
                images={images}
            />

            <div className="about-us container-fluid" style={{ padding: '10px 10px' }}>
                <div className="container">
                    <div className="row no-margin w-100">
                        <div style={{ margin: 'auto', textAlign: 'center' }}>
                            <Link to="/contact" className="btn btn-warning btn-sm "><span className="btn-title">Register Membership</span></Link>
                            &nbsp;&nbsp;
                            <Link to="/donate" className="btn btn-primary btn-sm "><span className="btn-title">Donate / Save Tax</span></Link>
                        </div>
                    </div>
                    <div className="row natur-row no-margin w-100">
                        <div className="text-part col-md-6" style={{ textAlign: 'center' }}>
                            <h4><strong>Kusum Educational Trust</strong></h4>
                            <p>Kusum Educational Trust (Registered NCT Delhi, Govt. of India) The Education Trust is a nonprofit organization
                                that promotes high academic achievement for all students. With a goal to close opportunity and achievement.</p>
                            <p>  An educational institution is a place where people of different ages gain an education, including preschools, childcare, primary-elementary
                                schools, secondary-high schools, and universities. They provide a large variety of learning environments and learning spaces.</p>
                            <p>visit: www.kusum.org.in | email: mail@kusum.org.in</p>
                        </div>
                        <div className="image-part col-md-6">
                            <div className="about-quick-box row">
                                <div className="col-md-6">
                                    <div className="about-qcard">
                                        <i className="fas fa-user" />
                                        <p>Become a Volunteer</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="about-qcard ">
                                        <i className="fas fa-search-dollar red" />
                                        <p>Quick Fundraiser</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="about-qcard ">
                                        <i className="fas fa-donate yell" />
                                        <p>Save Tax on Donation</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="about-qcard ">
                                        <i className="fas fa-hands-helping blu" />
                                        <p>Help Someone</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ################# Mission Vision Start Here #######################-*/}
            <section className="container-fluid mission-vision">
                <div className="container">
                    <div className="row mission">
                        <div className="col-md-6 mv-det">
                            <h1>Our Mission</h1>
                            <p>To preserve and provide a sustainable environment for healthy mothers, children and families by providing Nutrition,
                                Education and Counseling. To prevent child abandonment. To preserve family by meeting their needs through community programs,
                                To prevent child labour.</p>
                        </div>
                        <div className="col-md-6 mv-img">
                            <img src="assets/images/misin.jpg" alt />
                        </div>
                    </div>
                    <div className="row vision">
                        <div className="col-md-6 mv-img">
                            <img src="assets/images/vision.jpg" alt />
                        </div>
                        <div className="col-md-6 mv-det">
                            <h1>Our Vision</h1>
                            <p>Trust Vision Give every child an opportunity to succeed Cultivate a personalised learning pathway that challenges, engages and development and Support for students.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* ################# Events Start Here#######################-*/}
            <section className="events">
                <div className="container">
                    <div className="session-title row">
                        <h2>Popular Causes</h2>
                        <p>We are a non-profital &amp; Charity raising money for child education</p>
                    </div>
                    <div className="event-ro row">
                        <div className="col-md-4 col-sm-6">
                            <div className="event-box">
                                <img src="assets/images/events/image_08.jpg" alt />
                                <h4>Child Grow in India</h4>
                                <p className="raises"><span>Raised : Rs.1000</span> / INR </p>
                                <p className="desic">To ensure your child's growth and prevent, natural tricks that may help.</p>
                                <button className="btn btn-success btn-sm">Donate Now</button>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="event-box">
                                <img src="assets/images/events/image_06.jpg" alt />
                                <h4>Child Education in India</h4>
                                <p className="raises"><span>Raised : Rs.5000</span> / INR </p>
                                <p className="desic">preschools, childcare, primary-elementary schools, secondary-high schools.</p>
                                <button className="btn btn-success btn-sm">Donate Now</button>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="event-box">
                                <img src="assets/images/events/image_04.jpg" alt />
                                <h4>Livelihood projects in India</h4>
                                <p className="raises"><span>Raised : Rs.500</span> / INR </p>
                                <p className="desic">Livelihood projects have been in full swing to help the people re establish themselves.</p>
                                <button className="btn btn-success btn-sm">Donate Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ################# Charity Number Starts Here#######################-*/}
            <div className="doctor-message">
                <div className="inner-lay">
                    <div className="container">
                        <div className="row session-title">
                            <h2>Our Achievemtns in Numbers</h2>
                            <p>preschools, childcare, primary-elementary schools, secondary-high schools facilities.</p>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 numb">
                                <h3>7+</h3>
                                <span>YEARS OF EXPEREANCE</span>
                            </div>
                            <div className="col-sm-3 numb">
                                <h3>1812+</h3>
                                <span>HAPPY CHILDRENS</span>
                            </div>
                            <div className="col-sm-3 numb">
                                <h3>52+</h3>
                                <span>EVENTS</span>
                            </div>
                            <div className="col-sm-3 numb">
                                <h3>48+</h3>
                                <span>FUNT RAISED</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*################### Our Team Starts Here #######################-*/}
            <section className="our-team team-11">
                <div className="container">
                    <div className="session-title row">
                        <h2>Meet our Core Team</h2>
                        <p>The Trust is headed by a Board of Trustees core Team.</p>
                    </div>
                    <div className="row team-row">
                        <div className="col-md-3 col-sm-6">
                            <div className="single-usr">
                                <img src="assets/images/team/team-memb1.jpg" alt />
                                <div className="det-o">
                                    <h4>Ms. Kusum Devi</h4>
                                    <i>Trustee </i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-usr">
                                <img src="assets/images/team/team-memb2.jpg" alt />
                                <div className="det-o">
                                    <h4>Mr. Sanjay Bhagat</h4>
                                    <i>President-Founder</i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-usr">
                                <img src="assets/images/team/team-memb3.jpg" alt />
                                <div className="det-o">
                                    <h4>Mr. Shiv Kumar Tiwari</h4>
                                    <i>Vice President-Founder</i>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="single-usr">
                                <img src="assets/images/team/team-memb5.jpg" alt />
                                <div className="det-o">
                                    <h4>Mr. Vivek K. Choudhary</h4>
                                    <i>Secretary</i>
                                </div>
                            </div>
                        </div>
                        <div className="session-title row">
                            <h2>Meet our Trust Team</h2>
                            <p>An integral part of the Kusum Educational Trust Team</p>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/satyanarayan.jpg" alt />
                                    <div className="det-o">
                                        <h4>Mr. Satya Narayan Rai</h4>
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Satyam.jpg" alt />
                                    <div className="det-o">
                                        <h4>Mr. Satyam</h4>
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Sanjay Kumar Shukla.jpg" alt />
                                    <div className="det-o">
                                        <h4>Sh. Sanjay Kumar Shukla</h4>
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Sabhajeet Chaurasiya.jpg" alt />
                                    <div className="det-o">
                                        <h4>Sh. Sabhajeet Chaurasiyar</h4>
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (1).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (2).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (3).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (4).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (5).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (6).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (7).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (8).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (9).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (10).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (11).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/mem (12).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Amit Tiwari.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Bhagwan Tiwari.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Dipak Bhardwaj.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Kamla Devi.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Sapna Thakur.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/Sukhdev Prasad Kanojia.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (1).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (2).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (3).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (4).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (5).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (6).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (7).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (8).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (9).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/MB1 (10).jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/1.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>


                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/2.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/3.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/4.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/5.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/6.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/7.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/8.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/9.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/10.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/11.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/12.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/13.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/14.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/15.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/16.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/17.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/18.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/19.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/20.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/21.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/22.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/23.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="single-usr">
                                    <img src="assets/images/team/team1/24.jpg" alt />
                                    <div className="det-o">
                                        <h4 />
                                        <i>Trust Members</i>
                                    </div>
                                </div>
                            </div>

                        </div></div></div></section>
            {/* ################# Our Blog Starts Here#######################-*/}
            <section className="our-blog">
                <div className="container">
                    <div className="row session-title">
                        <h2> Our Help </h2>
                        <p>Take a look at what people say about us </p>
                    </div>
                    <div className="blog-row row">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-blog">
                                <figure>
                                    <img src="assets/images/events/image_01.jpg" alt />
                                </figure>
                                <div className="blog-detail">
                                    <small>By Admin | August 10 2018</small>
                                    <h4>Children for Education</h4>
                                    <p> one-third of all children who enroll in grade one reach grade eight. At least 35 million children aged 6 - 14 years do not attend school. 53% of girls in the age group of 5 to 9 years are illiterate.
                                    </p><div className="link">
                                        <a href>Read more </a><i className="fas fa-long-arrow-alt-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-blog">
                                <figure>
                                    <img src="assets/images/events/image_02.jpg" alt />
                                </figure>
                                <div className="blog-detail">
                                    <small>By Admin | August 10 2018</small>
                                    <h4>Women Development</h4>
                                    <p> Our working In Rural Women To Create Social And Environmental Change One Village At A Time. Working To Prevent Gender Inequality, Environmental Damage &amp; Poverty.</p>
                                    <div className="link">
                                        <a href>Read more </a><i className="fas fa-long-arrow-alt-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-blog">
                                <figure>
                                    <img src="assets/images/events/image_03.jpg" alt />
                                </figure>
                                <div className="blog-detail">
                                    <small>By Admin | August 10 2018</small>
                                    <h4>Transforming lives</h4>
                                    <p> The Educationa Trust is dedicated to transforming lives of children who hail from the lower of the society. We work as an after-school support.</p>
                                    <div className="link">
                                        <a href>Read more </a><i className="fas fa-long-arrow-alt-right" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <FooterComponent />

    </>
}

export default HomePage